<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="15" :md="8" :lg="6" :xl="4">
          <el-input v-model="params.keyWords" @change="changeParams" clearable placeholder="请输入用户ID/昵称/姓名/手机号"> </el-input>
        </el-col>
        <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="10">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="changeDate"
          >
          </el-date-picker>
          <el-button type="primary" class="margin-left20" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data.sync="tableList" border>
      <el-table-column label="用户ID" align="center">
        <template slot-scope="scope">
          <div class="flex justify-center">
            <div class="margin-right-xs">
              <img :src="scope.row.profilePic" alt style="width:48px;height:48px;" />
            </div>
            <div>
              <div>{{scope.row.trueName}}</div>
              <div>ID:{{ scope.row.userId }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品" align="center">
        <template slot-scope="scope">
          <div class="flex">
            <div class="margin-right-xs">
              <img :src="scope.row.goodsImg" alt style="width:48px;height:48px;" />
            </div>
            <div class="ellipsis2" style="height:40px">
              {{scope.row.goodsName}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="评论内容" align="center">
        <template slot-scope="scope">
          <div class="ellipsis2" style="height:40px">
            {{scope.row.content}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="评论图片" align="center">
        <template slot-scope="scope">
          <div class="flex justify-around">
            <img v-for="item in scope.row.evalList" :key="item" :src="item" alt style="width:48px;height:48px;" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="评论时间" align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateformat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" max-width="180" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0">
            <el-link style="margin:0 10px" type="success" :underline="false" @click="proagree(scope.row)">同意</el-link>
            <el-link style="margin:0 10px" type="danger" :underline="false" @click="prorefuse(scope.row)">删除</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { reviewPage, reviewShenhe } from '@/api/ShenheController'

export default {
  data() {
    return {
      tableList: [],
      params: {
        keyWords: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      date: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.fecthList()
  },
  methods: {
    
    changeParams(v) {
      !v && this.handleSearch()
    },
    changeDate(v) {
      if (v) {
        this.params.startTime = v[0]
        this.params.endTime = v[1]
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
        this.handleSearch()
      }
    },

    handleSearch() {
      console.log(this.params)
      this.fecthList()
    },

    async fecthList() {
      let [err, res] = await reviewPage(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取列表失败')
      }
      console.log(res)
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.fecthList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.fecthList()
    },

    proagree(row) {
      this.$confirm(`确定要审核同意吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let { ...ss } = {}
          ss.reviewId = row.reviewId
          ss.status = 1
          this.integralorderUpdateReq(ss)
        })
        .catch(() => {})
    },
    prorefuse(row) {
      this.$confirm(`确定要拒绝吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let { ...ss } = {}
          ss.reviewId = row.reviewId
          ss.status = 2
          this.integralorderUpdateReq(ss)
        })
        .catch(() => {})
    },
    async integralorderUpdateReq(data) {
      let [err, res] = await reviewShenhe(data)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      } else {
        this.$message.success('操作成功')
        this.getintegralorderListReq()
      }
    },
  },
  components: {
    TitleBarBaseVue
  }
}
</script>
